<template>
  <v-breadcrumbs :items="items"
                 divider=">">
    <template #item="{ item }">
      <v-breadcrumbs-item
        exact
        :to="{ name: item.link }"
        :disabled="item.disabled">
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {
      items: []
    };
  },

  watch: {
    $route() {
      this.updateList();
    }
  },

  mounted() {
    this.updateList();
  },

  methods: {
    goTo(payload) {
      if (payload) this.$router.push({ name: payload });
    },

    updateList() { this.items = this.$route.meta.breadCrumb; }
  }
};
</script>

<style>

</style>
